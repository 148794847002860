import { URL_BASE_API_DEV } from "data/config";
import TokenService from "lib/TokenService";
import { IResOrErr, requestExterno, requestGen } from "lib/httpClient";


interface ILoginResponse {
    nombres: string;
    paterno: string;
    materno: string;
    email: string;
    token: string;
    refreshToken: string;
}

export const LoginService = {
    signin(email: string, password: string): Promise<IResOrErr<ILoginResponse>> {
        return new Promise((resolve, eject) => {
            requestExterno.post(URL_BASE_API_DEV, "/auth/login", {
                email: email,
                password: password,
            })
              .then((response) => {
                resolve({
                    data: {
                        nombres: response.data.data.nombres,
                        paterno: response.data.data.paterno,
                        materno: response.data.data.materno,
                        email: response.data.data.email,
                        token: response.data.data.token,
                        refreshToken: response.data.data.refreshToken,
                    },
                    status: response.status,
                });
              })
              .catch((error) => {
                resolve({
                    error: error.response?.data?.error ?? "Error mientras se iniciaba sesión",
                    status: error.response?.status,
                });
              });
          });
    },
    signout(callback: VoidFunction) {
        setTimeout(callback, 100);
    },
    getCurrentUser(): Promise<IResOrErr<ILoginResponse>> {
        return new Promise((resolve, eject) => {
            if (TokenService.getLocalAccessToken() !== null) {
                requestGen.get(URL_BASE_API_DEV, "/usuarios/current")
                    .then((response) => {
                        resolve({
                            data: {
                                nombres: response.data.data.nombres,
                                paterno: response.data.data.paterno,
                                materno: response.data.data.materno,
                                email: response.data.data.email,
                                token: response.data.data.token,
                                refreshToken: response.data.data.refreshToken,
                            },
                            status: response.status,
                        });
                    })
                    .catch((error) => {
                        resolve({
                            error: error.response?.data?.error ?? "Error al obtener el usuario",
                            status: error.response?.status,
                        });
                    });
            } else {
                resolve({
                    error: "Sin token registrado",
                    status: 401,
                });
            }
        });
    }
};