import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card, { CardProps } from '@mui/material/Card';

const CustomCardStyled = styled(Card)<CardProps>(({ theme }) => ({
    borderRadius: '15px',
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
}));

export function CustomCard(props: any) {
  return <CustomCardStyled elevation={0} {...props} />;
}