/**
 * Obtiene HEX de color de acuerdo a texto dado
 * @param {string} string Texto
 * @param {number} saturation % de saturación
 * @param {number} lightness % de claridad
 * @returns HEX
 */
export default function stringToColor(string: string, saturation = 50, lightness = 50): string {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  
  return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
}