export interface IDashboardModel {
    nroTotalAislados: number;
    nroTotalEspecies: number;
    nroTotalUsuarios: number;
}

export const toDashboardModel = (data: any): IDashboardModel | undefined => {
    if (!data) return undefined;
    return {
        nroTotalAislados: data.nroTotalAislados,
        nroTotalEspecies: data.nroTotalEspecies,
        nroTotalUsuarios: data.nroTotalUsuarios,
    }
}