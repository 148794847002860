import { useCallback, useEffect, useState } from "react";
import TokenService from "lib/TokenService";
import parseJwt from "utils/parseJwt";
import useAuth from "./useAuth";


export interface Acceso {
    /** Nombrel del acceso */
    name: string;
    /** Permite escritura? */
    write: boolean;
}

export interface Role {
    /** Listado de roles de usuario */
    roles: string[];
    /** Listado de accesos de usuario */
    accesos: Acceso[];
    /** Estado de carga de roles */
    isLoading: boolean;
    /** Obtiene si acceso es de escritura */
    esAccesoWrite: (acceso: string) => boolean;
    /** Limpia roles y accesos */
    logout: () => void;
}

export default function useRoles(): Role {
    const user = useAuth();
    const [roles, setRoles] = useState<string[]>([]);
    const [accesos, setAccesos] = useState<Acceso[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const token = TokenService.getLocalAccessToken();
        const rolesData = token ? (parseJwt<any>(token)?.role ?? []) : [];
        const accesosData = token
            ? (
                parseJwt<any>(token)?.menus 
                    ? Array.isArray(parseJwt<any>(token)?.menus)
                        ? (
                            parseJwt<any>(token)?.menus?.map((a: any) => {
                                const nAcc = a.split("-");
                                return {
                                    name: nAcc[0],
                                    write: nAcc.length > 0 ? (nAcc[1] === "W") : true,
                                };
                            }) ?? []
                        ) 
                        : parseJwt<any>(token)?.menus
                    : []
            )
            : [];
        setRoles((Array.isArray(rolesData) ? [...rolesData].sort() : [rolesData]) ?? []);
        setAccesos((Array.isArray(accesosData) ? [...accesosData].sort() : [accesosData]) ?? []);
        setIsLoading(false);
    }, [user.user]);

    const esAccesoWrite = useCallback((acceso: string) => {
        return accesos.some(a => a.name === acceso && a.write) ?? false;
    }, [accesos]);

    const logout = useCallback(() => {
        setAccesos([]);
        setRoles([]);
    }, []);

    return { roles, accesos, isLoading, esAccesoWrite, logout };
}