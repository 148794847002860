import { ComponentType } from "react";
import { SvgIconProps } from "@mui/material";
import { IOpcionMenuNavProps } from "./IOpcionMenuNavProps";

export enum Position {
    Top = 1,
    Left = 2,
    Global = 3,
}

export interface IMenuNavProps {
    groupTitle: string;
    position: Position;
    hide?: boolean;
    groupIcon?: ComponentType<SvgIconProps>;
    options: IOpcionMenuNavProps[];
}