import { URL_BASE_API_DEV } from "data/config";
import { IResOrErr, requestGen } from "lib/httpClient";
import { IDashboardModel, toDashboardModel } from "./models/IDashboardModel";

export const DashboardService = {
    getIndicadoresAdmin(): Promise<IResOrErr<IDashboardModel>> {
        return new Promise((resolve, eject) => {
            requestGen.get(URL_BASE_API_DEV, "/dashboard/indicadoresAdmin")
                .then((response) => {
                    resolve({
                        data: toDashboardModel(response.data.data),
                        status: response.status,
                    });
                })
                .catch((error) => {
                    resolve({
                        error: error.response?.data?.error ?? "Error mientras se recuperaba la información",
                        status: error.response?.status,
                    });
                });
        })
    },
};