/**
 * Genera un saludo de acuerdo a la hora local del usuario
 * @param nombreUsuario Nombre del usuario
 * @returns 
 */
export default function getSaludo(nombreUsuario: string) {
    const fecha = new Date();
    if (fecha.getHours() < 12) return `Buenos días ${nombreUsuario}!`;
    if (fecha.getHours() < 19) return `Buenas tardes ${nombreUsuario}!`;
    if (fecha.getHours() < 24) return `Buenas noches ${nombreUsuario}!`;
    return `Hola ${nombreUsuario}`;
}