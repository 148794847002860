/**
 * Gestor de tokens de acceso y refresco en localStorage
 */
export const TokenService = {

    /**
     * Obtiene de localStorage string con token de acceso
     * @returns {string} Token de acceso 
     */
    getLocalAccessToken(): (string | null) {
        return window.localStorage.getItem('tknId');
    },

    /**
     * Obtiene de localStorage string con token de refresco
     * @returns {string} Token de acceso 
     */
    getLocalRefreshToken(): (string | null) {
        return window.localStorage.getItem('grantType');
    },

    /**
     * Escribe en localStorage token de acceso y token de refresco
     * @param {string} token Token de acceso
     * @param {string} refreshToken Token de refresco
     */
    setLocalTokens(token: string, refreshToken: string) {
        window.localStorage.setItem('tknId', token);
        window.localStorage.setItem('grantType', refreshToken);
    },

    /**
     * Elimina de localStorage token de acceso y token de refresco
     */
    removeTokens() {
        window.localStorage.removeItem("tknId");
        window.localStorage.removeItem("grantType");
    },
}

export default TokenService;