// React
import React, { FunctionComponent, Suspense, useCallback } from 'react';
// Material-UI
import { Box, CssBaseline, LinearProgress, Toolbar } from '@mui/material';
// Otras libs
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { v4 as uuid_v4 } from 'uuid';
// Interfaces
import { INavegacionProps } from './INavegacionProps';
// Hooks
import useAuth from 'hooks/useAuth';
import { Position } from './IMenuNavProps';
import { LeftMenu } from './LeftMenu';
import { TopMenu } from './TopMenu';

const drawerWidth = 250;

function RequireAuth({ children }: { children: JSX.Element }) {
    const auth = useAuth();
    const location = useLocation();
  
    if (!auth.user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
}

export const Navegacion: FunctionComponent<INavegacionProps> = (props) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();

    const handleDrawerToggle = useCallback (() => {
        setMobileOpen(prev => !prev);
    }, []);

    React.useEffect(() => {
        document.title = props.appName + " - " + (props.navegacion
            .flatMap(m => m.options)
            .map(m => ({link: m.link, title: m.title }))
            .concat(props.navegacion
                .flatMap(m => m.options)
                .flatMap(m => m.subOptions)
                .map(m => ({link: m.link, title: m.title})))
                .find(m => m.link === location.pathname)?.title ?? '')
    }, [location, props.appName, props.navegacion]);

    const menusIzq = React.useMemo(() => {
        return props.navegacion.filter(n => n.position === Position.Left && !n.hide);
    }, [props.navegacion]);

    return(
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <TopMenu
                menus={props.navegacion.filter(n => n.position === Position.Top).flatMap(m => m.options)}
                title={props.titulo}
                drawerWidth={drawerWidth}
                isLoginPage={location.pathname === "/login"}
                backgroundColor={props.topMenuProps?.backgroundColor}
                color={props.topMenuProps?.color}
                navSuperior={props.navSuperior}
                navSuperiorProps={props.navSuperiorProps}
                menusLeft={props.navSuperior ? props.navegacion.filter(n => n.position === Position.Left) : undefined}
                onDrawerToggle={handleDrawerToggle}
            />
            <LeftMenu
                menus={menusIzq}
                drawerWidth={drawerWidth}
                mobileOpen={mobileOpen}
                mostrar={!props.navSuperior}
                bgColor={props.leftMenuProps?.backgroundColor}
                itemProps={props.leftMenuProps?.itemProps}
                subItemProps={props.leftMenuProps?.subItemProps}
                selectedItemProps={props.leftMenuProps?.selectedItemProps}
                loginButtonProps={props.leftMenuProps?.loginButtonProps}
                esLogin={location.pathname === "/login"}
                logo={props.logo}
                onDrawerToggle={handleDrawerToggle}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh', width: (location.pathname !== "/login" && !props.navSuperior) ? { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` } : "100%" }}>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 0 }}
                >
                    {location.pathname !== "/login" ? <Toolbar sx={{ display: {xs: 'block', sm: 'none' }, minHeight: props.navSuperior ? { sm: "100px" } : undefined }} /> : null}
                    <Suspense fallback={<LinearProgress style={{ marginTop: "0px", color: "#04afde" }} />}>
                        <Box sx={{ paddingTop: props.navSuperior ? { sm: "40px" } : undefined }}>
                            <Routes>
                                {props.navegacion
                                    .flatMap(m => m.options)
                                    .map(opcion => (
                                        <React.Fragment key={uuid_v4()}>
                                            <Route 
                                                path={opcion.link} 
                                                element={!opcion.protected 
                                                    ? opcion.component 
                                                    : <RequireAuth>{opcion.component}</RequireAuth>
                                                }
                                                errorElement={props.errorPage}
                                            />
                                            {opcion.subOptions.map(subOption => (
                                                <Route 
                                                    key={uuid_v4()}
                                                    path={subOption.link} 
                                                    element={!subOption.protected 
                                                        ? subOption.component 
                                                        : <RequireAuth>{subOption.component}</RequireAuth>
                                                    }
                                                    errorElement={props.errorPage}
                                                />
                                            ))}
                                    </React.Fragment>
                                ))}
                                <Route path="/login" element={props.loginPage}/>
                                <Route path="/logout" element={props.logoutPage}/>
                                <Route path="*" element={props.notFoundPage}/>
                            </Routes>
                        </Box>    
                    </Suspense>
                </Box>
                {props.footer && location.pathname !== "/login" &&
                    <Box component="footer" style={{ marginTop: "30px", backgroundColor: props.footer.bgColor, height: props.footer.height ?? 60 }}>
                        {props.footer.element}
                    </Box>
                }
            </Box>
        </Box>
    );
};