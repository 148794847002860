// React
import React from 'react';
// Material-UI
import { Box, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import InsertChartTwoToneIcon from '@mui/icons-material/InsertChartTwoTone';
import GradeTwoToneIcon from '@mui/icons-material/GradeTwoTone';
// Otras libs

// Componentes compartidos
import { CustomCard } from 'components/CustomCard';
import { MenuOption } from 'components/MenuOption';
// Features
import SaludoUsuario from 'features/saludoUsuario';
import Indicadores from 'features/indicadores';
// Hooks
import { useNavigate } from 'react-router-dom';
import useRoles from 'hooks/useRoles';


export default function MyPage() {
    // Navegación
    const navigate = useNavigate();
    // Token
    const { accesos } = useRoles();

    return (
        <Container maxWidth="md" style={{ paddingTop: 50, paddingBottom: 50 }}>
            <Grid container spacing={3}>
                <Grid item container xs={12} md={12}>
                    <Grid item xs={12} md={12}>
                        <Typography variant='h4' style={{ marginBottom: 20 }}>Dashboard</Typography>
                        <SaludoUsuario />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <CustomCard>
                        <CardContent style={{ padding: 0 }}>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Box display="flex" alignItems="center" pl={2} pt={2} pb={2}>
                                        <InsertChartTwoToneIcon htmlColor="#0081C9"/>
                                        <Typography variant='h6' style={{ paddingLeft: 20 }}>Indicadores</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Indicadores/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CustomCard>
                </Grid>
                <Grid item xs={12} md={12}>
                    <CustomCard>
                        <CardContent style={{ padding: 0 }}>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Box display="flex" alignItems="center" pl={2} pt={2} pb={2}>
                                        <GradeTwoToneIcon htmlColor="#F2921D"/>
                                        <Typography variant='h6' style={{ paddingLeft: 20 }}>Accesos rápidos</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Divider/>        
                                </Grid>
                                <Grid item container xs={12} md={12} spacing={3} p={3}>
                                    {accesos.some(a => a.name === 'AIS') && (
                                        <Grid item xs={12} md={6}>
                                            <MenuOption
                                                title="Gestor de aislados"
                                                description="Crea o edita aislados"
                                                Icon={InsertChartTwoToneIcon}
                                                iconColor="#0081C9"
                                                onClick={() => navigate("/aislados")}
                                            />  
                                        </Grid>
                                    )}
                                    {accesos.some(a => a.name === 'USR') && (
                                        <Grid item xs={12} md={6}>
                                            <MenuOption
                                                title="Gestor de usuarios"
                                                description="Gestiona las cuentas de usuario"
                                                Icon={InsertChartTwoToneIcon}
                                                iconColor="#635985"
                                                onClick={() => navigate("/gestor-usuarios/usuarios/paginador")}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CustomCard>
                </Grid>
            </Grid>
        </Container>
    );
}