import React, { FunctionComponent } from 'react';
import { Box, Link, Divider, Grid, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ICustomFooterProps } from './ICustomFooterProps';


export const CustomFooter: FunctionComponent<ICustomFooterProps> = (props) => {
    const instagram = 'https://www.instagram.com/proyectoremc/';
    return (
        <Grid container spacing={1} sx={{ backgroundColor: props.bgColor }}>
            <Grid item xs={12} md={12} style={{ paddingTop: '0' }}>
                <Divider />
            </Grid>
            <Grid item container xs={12} md={5} marginLeft={{ md: 5 }}>
                <Grid item xs={12} md={12} margin={2} marginBottom={1}>
                    <Typography variant='h6' fontWeight={700} align='left' color={props.titleColor}>
                        Financiamiento ANID 2021 
                    </Typography>
                    <Divider color={props.titleColor} style={{ width: '90%', borderBottomWidth: 3 }} />
                    <Typography variant='body1' style={{ fontWeight: 800, color: props.color, marginTop:'10px' }}>Fondo Estratégico de Investigación en Sequía</Typography>
                     
                </Grid>
                <Grid item container xs={12} md={12} display='flex' justifyContent={'left'} marginLeft={2}>
                    <Box component='div' width={300} padding={'10px'}>
                         <a href='https://www.anid.cl'>
                            {' '}
                            <img
                                src='assets/images/anid.png'
                                alt='Imagen de planta'
                                width={130}
                                style={{ float: 'left', position: 'relative' }}
                            />
                        </a>
                        <Box component='div' marginLeft={'150px'} position='absolute'>
                            <Typography variant='h5' style={{ fontWeight: 800, color: props.color }}>
                                Proyecto
                                </Typography>
                            <Typography variant='body1' style={{ fontWeight: 800, color: props.color }}>
                                Rhizosphere Extreme <br />Microbiome Consortium<br />
                                FSEQ210003
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid item xs={12} md={12} margin={2}>
                    <Typography variant='h6' align='left' color={props.titleColor} fontWeight={700}>
                        Instituciones
                    </Typography>
                    <Divider color={props.titleColor} style={{ width: '90%', borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: 'left', flexFlow: 'wrap' }} display='flex'>
                    <Link href='https://www.uantof.cl' target="_blank" style={{ margin: 'auto', verticalAlign: 'center' }}>
                        {' '}
                        <img src='assets/images/ua_cuadrado.png' alt='Imagen UA' width={130} />{' '}
                    </Link>
                    <Link href='https://www.uv.cl' target="_blank" style={{ margin: 'auto', verticalAlign: 'center' }}>
                        {' '}
                        <img src='assets/images/UV.png' alt='Imagen UV' width={180} />
                    </Link>
                    <Link href='https://www.ufro.cl' target="_blank" style={{ margin: 'auto', verticalAlign: 'center' }}>
                        {' '}
                        <img src='assets/images/Logo-UFRO.png' alt='Imagen UFRO' width={130} />
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={12} display='flex' textAlign='center' marginTop={5} justifyContent='center'>
                <Typography variant='body2' fontWeight={'800'} color='primary'>Siguenos en </Typography>
                <Link href={instagram} underline="hover" target="_blank"><InstagramIcon fontSize='small' color='primary' sx={{ marginLeft: '3px' }} /></Link>
            </Grid>
            <Grid item xs={12} textAlign='center' marginTop={1} padding={2} bgcolor={props.subBgColor}>
                <Typography variant='h6' textAlign='center' color={props.color} fontWeight={500} fontSize={12} pt={1}>
                    Plataforma desarrollada por <em style={{ fontWeight: '700' }} color={props.color}><Link href="https://www.damoreto.com" underline="hover" target="_blank">Damoreto</Link ></em> para la Universidad de Antofagasta y asociados.
                </Typography>
            </Grid>
        </Grid>
    );
};
