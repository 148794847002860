import React from 'react';

import TokenService from 'lib/TokenService';

interface IUser {
  nombres: string;
  email: string;
  token: string;
  refreshToken: string;
  roles: string[];
}

interface AuthContextType {
  user: IUser | null;
  signin: (user: IUser) => void;
  signout: () => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);


export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<IUser | null>(null);
  
  const signin = (newUser: IUser) => {
    TokenService.setLocalTokens(newUser.token, newUser.refreshToken);
    setUser(newUser);
  };
  
  const signout = () => {
    TokenService.removeTokens();
    setUser(null);
  };
  
  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}