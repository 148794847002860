// React
import React, { FunctionComponent, useMemo } from "react";
// Material-UI
import { Box, Drawer, Divider, List, ListItemButton, ListItemText, ListItemIcon, Typography, Toolbar, Button, Avatar } from '@mui/material';
// Otras libs
import { Link, NavLink, NavLinkProps } from 'react-router-dom';
import { v4 as uuid_v4 } from 'uuid';
// Interfaces
import { ILeftMenuProps } from "./ILeftMenuProps";
import { Position } from "./IMenuNavProps";
import { CollapseList } from "./CollapseList";
import useAuth from "hooks/useAuth";
import { Logout } from "@mui/icons-material";
import stringToColor from "utils/stringToColor";
import getInitials from "utils/getInitials";
// Hooks

type NavLinkWrapperProps = NavLinkProps & {
    itembgcolor?: string;
    itemcolor?: string;
    itemselectedcolor?: string;
  }
const MyNavLink = React.forwardRef<NavLinkWrapperProps, any>((props, ref) => {
    return (
    <NavLink
        {...props}
        ref={ref}
        to={props.to}
        style={({ isActive }) => ({ 
            color: isActive ? (props.itemselectedcolor ? props.itemselectedcolor : '#0072e5') : (props.itemcolor ? props.itemcolor : 'black'), 
            backgroundColor: isActive ? (props.itembgcolor ? props.itembgcolor : '#3f357b') : 'transparent',
            borderRadius: 5,
            margin: 10,
            padding: "5px 15px 5px 15px",
        })}
    >
      {props.children}
    </NavLink>
  )});

export const LeftMenu: FunctionComponent<ILeftMenuProps> = (props) => {
    const auth = useAuth();

    const menuOpciones = useMemo(() => props.menus.filter(n => n.position === Position.Left && !n.hide).map(m => (
            <React.Fragment key={uuid_v4()}>
                {m.groupTitle !== "" &&
                    <CollapseList title={m.groupTitle} color={props.itemProps?.color} icon={m.groupIcon}>
                        <List component="div" disablePadding>
                            {m.options.filter(op => !op.hide).map((option) => (
                                <ListItemButton 
                                    key={uuid_v4()} 
                                    component={MyNavLink} 
                                    itembgcolor={props.selectedItemProps?.bgColor} 
                                    itemcolor={props.itemProps?.color} 
                                    itemselectedcolor={props.selectedItemProps?.color}
                                    sx={{ color: 'inherit' }} 
                                    to={option.link}
                                >
                                    <ListItemIcon>
                                        {<option.icon fontSize="small" sx={{ color: props.subItemProps?.color }}/>}
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body1" fontWeight={600} fontSize={15} color='inherit'>{option.title}</Typography>} />
                                </ListItemButton>
                            ))}
                        </List>
                    </CollapseList>
                }
                {m.groupTitle === "" &&
                    <List subheader={m.groupTitle !== "" ? <Typography variant="overline" m={2}>{m.groupTitle}</Typography> : undefined} onClick={props.mobileOpen ? props.onDrawerToggle : undefined}>
                        {m.options.filter(op => !op.hide).map((option) => (
                            <ListItemButton 
                                key={uuid_v4()} 
                                component={MyNavLink} 
                                itembgcolor={props.selectedItemProps?.bgColor} 
                                itemcolor={props.itemProps?.color} 
                                itemselectedcolor={props.selectedItemProps?.color}
                                sx={{ color: 'inherit' }} 
                                to={option.link}
                            >
                                <ListItemIcon>
                                    {<option.icon fontSize="small" sx={{ color: props.itemProps?.color }}/>}
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="body1" fontWeight={600} fontSize={15} color='inherit'>{option.title}</Typography>} />
                            </ListItemButton>
                        ))}
                    </List>
                }
                <Divider variant="middle" />  
            </React.Fragment>  
          ))
    , [props.itemProps?.color, props.menus, props.mobileOpen, props.onDrawerToggle, props.selectedItemProps?.bgColor, props.selectedItemProps?.color, props.subItemProps?.color]); ;

    const drawer = useMemo(() => (
        <Box sx={{
            width: '100%',
            height: "100%",
            overflowX: 'hidden',
        }}
        >
            <Toolbar>{props.logo}</Toolbar>        
            <Divider />
            <Box position="relative" height="calc(100vh - 80px)" width="99%">
                <Box 
                    position="absolute" 
                    top={0} 
                    left={0} 
                    height="calc(100vh - 128px)" 
                    width="99%" 
                    sx={{ 
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': { width: '6px' },
                        '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
                        '&::-webkit-scrollbar-thumb': { background: '#888' },
                        '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
                }}>
                    {menuOpciones}
                </Box>
                <Box position="absolute" bottom={0} left={0} width="100%" height="64px" display={{ xs: 'none', md: 'block' }}>
                    <Box m={1} p={1} bgcolor={props.loginButtonProps?.bgColor ?? "gray"} borderRadius="10px">
                        {auth.user
                            ? (
                                <Box display="flex">
                                    <Avatar
                                        sx={{ bgcolor: stringToColor(auth.user?.nombres ?? "U") }} 
                                        children={getInitials(auth.user?.nombres ?? "U")}
                                    /> 
                                    <Button component={Link} startIcon={<Logout fontSize="small" />} to="/logout" sx={{ color: props.loginButtonProps?.color ?? "white" }}>
                                        Cerrar sesión
                                    </Button>
                                </Box>
                            )
                            : (
                                <>
                                    <Button component={Link} to={"/login"} sx={{ 
                                            display: { xs: 'none', sm: 'block', color: props.loginButtonProps?.color ?? "white", textAlign: "center" } 
                                        }}
                                    >
                                        Iniciar sesión
                                    </Button>
                                </>
                            )
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    ), [auth.user, menuOpciones, props.loginButtonProps?.bgColor, props.loginButtonProps?.color, props.logo]);

    return (!props.esLogin ?
        <Box
            component="nav"
            sx={{ width: props.mostrar ? { sm: props.drawerWidth } : undefined, flexShrink: { sm: 0 } }}
        >
            <Drawer
                variant="temporary"
                open={props.mobileOpen}
                onClose={props.onDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: props.bgColor ? props.bgColor : '#201643',
                        border: 0,
                    }
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            {props.mostrar &&
                <Drawer
                    variant="permanent"
                    open
                    PaperProps={{
                        sx: {
                            backgroundColor: props.bgColor ? props.bgColor : '#201643',
                            border: 0,
                        }
                    }}
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            }
        </Box> : null
    );
};