import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import TokenService from './TokenService';
import { URL_BASE_API_DEV } from 'data/config';

//axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const axiosApiInstance = axios.create();
const axiosRefreshInstance = axios.create();
const axiosExternos = axios.create();

axiosApiInstance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            if (config.headers) config.headers.Authorization = `Bearer ` + token;
            return config;
        }
        return config
    },
    (error) => {
        return Promise.reject(error);
    }
);

async function refreshAuthLogic(failedRequest: any) {
    try {
        const resp = await axiosRefreshInstance.post(`${URL_BASE_API_DEV}/auth/refreshToken`, {
            refreshToken: TokenService.getLocalRefreshToken(),
            pauseInstanceWhileRefreshing: true,
        });
        TokenService.setLocalTokens(resp?.data?.data?.token, resp.data?.data?.refreshToken);
        failedRequest.response.config.headers.Authorization = `Bearer ${resp.data?.data?.token}`;
        return Promise.resolve();
    } catch (error) {
        TokenService.removeTokens();
        window.location.href = "/login";
        return Promise.reject(error);
    }
}

// Instanciar el interceptor
createAuthRefreshInterceptor(axiosApiInstance, refreshAuthLogic, {
    statusCodes: [401, 403],
});

export interface IResOrErr<T> {
    data?: T;
    error?: string;
    status: number;
}

export interface IPagResOrErr<T> {
    data?: {
        data: T[];
        pageNumber: number;
        pageSize: number;
        totalPages: number;
        totalRecords: number;
    };
    error?: string;
    status: number;
}

export const requestGen = {
    /**
     * Petición HTTP - GET
     * @param urlBase Url de API que se quiere acceder. Ejemplo: `https://api.ejemplo.com`
     * @param path Ruta relativa del recurso al que se requiere acceder. Ejemplo: `/users/2`
     * @param config Objeto con configuraciones de petición
     * @returns Respuesta de petición
     */
    get : (urlBase: string, path: string, config: any = undefined) => axiosApiInstance.get(`${urlBase}${path}`, config),

    /**
     * Petición HTTP - POST
     * @param urlBase Url de API que se quiere acceder. Ejemplo: `https://api.ejemplo.com`
     * @param path Ruta relativa del recurso al que se requiere acceder. Ejemplo: `/users/2`
     * @param body Objeto con data de petición
     * @param config Objeto con configuraciones de petición
     * @returns 
     */
    post : (urlBase: string, path: string, body: any, config: any = undefined) => axiosApiInstance.post(`${urlBase}${path}`, body, config),

    /**
     * Petición HTTP - PUT
     * @param urlBase Url de API que se quiere acceder. Ejemplo: `https://api.ejemplo.com`
     * @param path Ruta relativa del recurso al que se requiere acceder. Ejemplo: `/users/2`
     * @param body Objeto con data de petición
     * @param config Objeto con configuraciones de petición
     * @returns 
     */
    put : (urlBase: string, path: string, body: any, config: any = undefined) => axiosApiInstance.put(`${urlBase}${path}`, body, config),

    /**
     * Petición HTTP - DELETE
     * @param urlBase Url de API que se quiere acceder. Ejemplo: `https://api.ejemplo.com`
     * @param path Ruta relativa del recurso al que se requiere acceder. Ejemplo: `/users/2`
     * @param config Objeto con configuraciones de petición
     * @returns 
     */
    delete: (urlBase: string, path: string, config: any = undefined) => axiosApiInstance.delete(`${urlBase}${path}`, config),
};

export const requestExterno = {
    /**
     * Petición HTTP - GET
     * @param urlBase Url de API que se quiere acceder. Ejemplo: `https://api.ejemplo.com`
     * @param path Ruta relativa del recurso al que se requiere acceder. Ejemplo: `/users/2`
     * @param config Objeto con configuraciones de petición
     * @returns Respuesta de petición
     */
    get : (urlBase: string, path: string, config: any = undefined) => axiosExternos.get(`${urlBase}${path}`, config),

    /**
     * Petición HTTP - POST
     * @param urlBase Url de API que se quiere acceder. Ejemplo: `https://api.ejemplo.com`
     * @param path Ruta relativa del recurso al que se requiere acceder. Ejemplo: `/users/2`
     * @param body Objeto con data de petición
     * @param config Objeto con configuraciones de petición
     * @returns 
     */
    post : (urlBase: string, path: string, body: any, config: any = undefined) => axiosExternos.post(`${urlBase}${path}`, body, config),

    /**
     * Petición HTTP - PUT
     * @param urlBase Url de API que se quiere acceder. Ejemplo: `https://api.ejemplo.com`
     * @param path Ruta relativa del recurso al que se requiere acceder. Ejemplo: `/users/2`
     * @param body Objeto con data de petición
     * @param config Objeto con configuraciones de petición
     * @returns 
     */
    put : (urlBase: string, path: string, body: any, config: any = undefined) => axiosExternos.put(`${urlBase}${path}`, body, config),

    /**
     * Petición HTTP - DELETE
     * @param urlBase Url de API que se quiere acceder. Ejemplo: `https://api.ejemplo.com`
     * @param path Ruta relativa del recurso al que se requiere acceder. Ejemplo: `/users/2`
     * @param config Objeto con configuraciones de petición
     * @returns 
     */
    delete: (urlBase: string, path: string, config: any = undefined) => axiosExternos.delete(`${urlBase}${path}`, config),
};