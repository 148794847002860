// React
import React, { FunctionComponent } from "react";
// Material-UI
import { AppBar, Box, IconButton, Typography, Toolbar, Button, Avatar, MenuItem, ListItemIcon, Divider, Grid } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';
// Otras libs
import { v4 as uuid_v4 } from 'uuid';
import { Link } from 'react-router-dom';
// Componentes
import { ButtonMenu } from "components/ButtonMenu";
// Funciones
import stringToColor from "utils/stringToColor";
import getInitials from "utils/getInitials";
// Interfaces
import { ITopMenuProps } from "./ITopMenuProps";
// Hooks
import useAuth from "hooks/useAuth";
import { Dropdown } from "./Dropdown";


export const TopMenu: FunctionComponent<ITopMenuProps> = (props) => {
    const auth = useAuth();

    return (
        <AppBar
            position="fixed"
            color="transparent"
            elevation={0}
            sx={{
                width: { sm: (!props.isLoginPage && !props.navSuperior) ? `calc(100% - ${props.drawerWidth}px)` : "100%" },
                ml: { sm: (!props.isLoginPage && !props.navSuperior) ? `${props.drawerWidth}px` : 0 },
                backdropFilter:"blur(8px)",
                backgroundColor: props.backgroundColor ? props.backgroundColor : "rgba(255,255,255,0.8)",
                color: props.color ? props.color : "black",
                borderStyle: "solid",
                borderColor: "#E7EBF0",
                borderWidth: 0,
                borderBottomWidth: "thin",
                display: { xs: 'block', sm: 'none' }
            }}
        >
            <Toolbar sx={{ minHeight: { sm: (props.navSuperior && !props.isLoginPage) ? 128 : undefined } }}>
                <Grid container>
                    <Grid item container xs={12} md={12} style={{ alignItems: "center" }}>
                        <IconButton
                            color="inherit"
                            aria-label="Abrir menú"
                            edge="start"
                            onClick={props.onDrawerToggle}
                            sx={{ mr: 2, display: { xs: props.isLoginPage ? 'none' : undefined , sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            {props.title}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        {auth.user
                            ? (
                                <ButtonMenu 
                                    tooltip="Cuenta"
                                    icon={
                                        <Avatar 
                                            sx={{ bgcolor: stringToColor(auth.user?.nombres ?? "U") }} 
                                            children={getInitials(auth.user?.nombres ?? "U")}
                                        /> 
                                    }
                                >
                                    {props.menus.map(m => (
                                        <MenuItem key={uuid_v4()} component={Link} to={m.link}>
                                            <ListItemIcon>
                                                {<m.icon/>}
                                            </ListItemIcon>
                                            {m.title}
                                        </MenuItem>
                                    ))}
                                    <Divider/>
                                    <MenuItem component={Link} to="/logout">
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Cerrar sesión
                                    </MenuItem>
                                </ButtonMenu>
                            )
                            : (props.isLoginPage === false
                                ? (<>
                                    <Button variant="outlined" component={Link} to={"/login"} sx={{ display: { xs: 'none', sm: 'block' }, color: props.color ? props.color : "white", borderColor: props.color ? props.color : "white" }}>Iniciar sesión</Button>
                                    <IconButton color="inherit" component={Link} to={"/login"} sx={{ display: { sm: 'none' } }}><AccountCircleOutlinedIcon /></IconButton>
                                </>)
                                : null
                            )
                        }
                    </Grid>
                    
                    {props.navSuperior && !props.isLoginPage &&
                        <Grid item xs={12} md={12} sx={{ display: { xs: 'none', sm: 'flex' }, height: "50px", alignItems: 'end' }}>
                            {props.menusLeft?.map((option) => {
                                if (option.groupTitle === "") {
                                    return (
                                        option.options.map(o => (
                                            <Dropdown
                                                key={uuid_v4()}
                                                title={o.title}
                                                link={o.link}
                                                Icon={o.icon}
                                                disabled={o.disabled}
                                                hide={o.hide}
                                                backgroundColor={props.navSuperiorProps?.backgroundColor}
                                                menuBgColor={props.navSuperiorProps?.menuBgColor}
                                                color={props.navSuperiorProps?.color}
                                                optionBgColor={props.navSuperiorProps?.optionBgColor}
                                                optionColor={props.navSuperiorProps?.optionColor}
                                            />    
                                        ))
                                    );
                                } else {
                                    return (
                                        <Dropdown
                                            key={uuid_v4()}
                                            title={option.groupTitle}
                                            backgroundColor={props.navSuperiorProps?.backgroundColor}
                                            menuBgColor={props.navSuperiorProps?.menuBgColor}
                                            color={props.navSuperiorProps?.color}
                                            optionBgColor={props.navSuperiorProps?.optionBgColor}
                                            optionColor={props.navSuperiorProps?.optionColor}
                                            options={option.options.map(o => ({
                                                title: o.title,
                                                icon: o.icon,
                                                link: o.link,
                                                disabled: o.disabled,
                                                hide: o.hide,
                                            }))}
                                        />
                                    );
                                }
                            })}
                        </Grid>
                    }
                </Grid>
            </Toolbar>
        </AppBar>
    );
};