// React
import React, { FunctionComponent } from "react";
// Material-UI
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// Otras libs
import { Link, NavLink } from "react-router-dom";
import { v4 as uuid_v4 } from 'uuid';
// Interfaces
import { IDropdownProps } from "./IDropdownProps";

const MyNavLink = React.forwardRef<any, any>((props, ref) => (
    <NavLink
        {...props}
        ref={ref}
        to={props.to}
        style={({ isActive }) => ({ 
            fontWeight: isActive ? 700 : 500,
        })}
    >
      {props.children}
    </NavLink>
  ));


export const Dropdown: FunctionComponent<IDropdownProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const _handleClick = (event: any) => {
        if (anchorEl !== event.currentTarget) {
          setAnchorEl(event.currentTarget);
        }
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {(!props.options || props.options.length === 0) && props.link &&
                <Button 
                    component={MyNavLink} 
                    to={props.link} 
                    sx={{ color: props.color, backgroundColor: props.backgroundColor, textTransform: 'none', fontWeight: 500, fontSize: '1rem', marginRight: "10px" }}
                >
                    {props.title}
                </Button>    
            }
            {props.options && props.options.length > 0 &&
                <Button
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup={"true"}
                    onClick={_handleClick}
                    onMouseOver={_handleClick}
                    sx={{ color: props.color, backgroundColor: props.backgroundColor, textTransform: 'none', fontWeight: 500, fontSize: '1rem', marginRight: "10px" }}
                >
                    {props.title}<KeyboardArrowDownIcon style={{ fontSize: '0.9rem' }}/>
                </Button>    
            }
            {props.options && props.options.length > 0 &&
                <Menu
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                    PaperProps={{ style: { backgroundColor: props.menuBgColor } }}
                >
                    {props.options?.map(o => (
                        <MenuItem 
                            key={uuid_v4()} 
                            component={Link} 
                            to={o.link} 
                            onClick={handleClose}
                            style={{ backgroundColor: props.optionBgColor, color: props.optionColor, fontSize: '0.95rem', }}
                        >
                            {<o.icon/>}<span style={{ marginLeft: 5 }}>{o.title}</span>
                        </MenuItem>
                    ))}
                </Menu>
            }
        </div>
    );
};