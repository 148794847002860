// React
import React, { FunctionComponent } from 'react';
// Material-UI
import { CardContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// Otras libs

// Componentes compartidos
import { CustomCard } from 'components/CustomCard';
// Interfaces
import { IMenuOptionProps } from './IMenuOptionProps';
// Hooks

export const MenuOption: FunctionComponent<IMenuOptionProps> = (props) => {
    return (
        <CustomCard variant='outlined'>
            <CardContent>
                <List>
                    <ListItem disablePadding onClick={props.onClick}>
                        <ListItemButton>
                            <ListItemIcon>
                                <props.Icon htmlColor={props.iconColor} fontSize='large' />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant='h6'>{props.title}</Typography>}
                                secondary={props.description}
                            />
                            <ArrowForwardIcon htmlColor="#0081C9"/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </CardContent>
        </CustomCard>
    );
};