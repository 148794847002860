// React
import React, { useEffect, useState } from 'react';
// Material-UI
import { Box, CardContent, Grid, Typography } from '@mui/material';
import BubbleChartTwoToneIcon from '@mui/icons-material/BubbleChartTwoTone';
import AirTwoToneIcon from '@mui/icons-material/AirTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
// Otras libs

// Componentes

// Services

// Hooks
import { CustomCard } from 'components/CustomCard';
import { IDashboardModel } from 'services/models/IDashboardModel';
import { DashboardService } from 'services/DashboardService';


export default function Indicadores() {
    // Token
    
    // Navegación
    
    // Estados
    const [state, setState] = useState<IDashboardModel>({
        nroTotalAislados: 0,
        nroTotalEspecies: 0,
        nroTotalUsuarios: 0,
    });
    // Listas

    // Funciones
    useEffect(() => {
        const getIndicadores = async () => {
            const res = await DashboardService.getIndicadoresAdmin();
            setState({
                nroTotalAislados: res.data?.nroTotalAislados ?? 0,
                nroTotalEspecies: res.data?.nroTotalEspecies ?? 0,
                nroTotalUsuarios: res.data?.nroTotalUsuarios ?? 0,
            })
        }
        getIndicadores();
    }, []);


    // Vista
    return (
        <Box>
            <Grid container spacing={2} p={3}>
                <Grid item xs={12} md={4}>
                    <CustomCard>
                        <CardContent>
                            <BubbleChartTwoToneIcon style={{ fontSize: 50 }} htmlColor="#13005A"/>
                            <Typography variant='body1'>N° de aislados ingresados en la plataforma</Typography>
                            <Typography variant='h4'>{state.nroTotalAislados}</Typography>
                        </CardContent>
                    </CustomCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomCard>
                        <CardContent>
                            <AirTwoToneIcon style={{ fontSize: 50 }} htmlColor="#00337C"/>
                            <Typography variant='body1'>N° de especies ingresadas en la plataforma</Typography>
                            <Typography variant='h4'>{state.nroTotalEspecies}</Typography>
                        </CardContent>
                    </CustomCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomCard>
                        <CardContent>
                            <GroupAddTwoToneIcon style={{ fontSize: 50 }} htmlColor="#1C82AD"/>
                            <Typography variant='body1'>N° de investigadores registrados</Typography>
                            <Typography variant='h4'>{state.nroTotalUsuarios}</Typography>
                        </CardContent>
                    </CustomCard>
                </Grid>
            </Grid>
        </Box>
    );
}