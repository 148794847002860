import React from 'react';
import ReactDOM from 'react-dom/client';
import CacheBuster from 'react-cache-buster';
import { Toaster } from 'react-hot-toast';
import '@fontsource/montserrat';
import '@fontsource/kumbh-sans';
import '@fontsource/roboto';
//import '@fontsource/roboto/300.css';
//import '@fontsource/roboto/400.css';
//import '@fontsource/roboto/500.css';
//import '@fontsource/roboto/700.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from 'context/auth/AuthProvider';
import ScrollToTop from 'lib/scrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const isProduction = process.env.NODE_ENV === 'production';
const appName = "Plataforma REMC";
const version = '0.8.0';

root.render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<div>Cargando...</div>} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <div>
        <div>
          <Toaster
            position="bottom-center"
            toastOptions={{
              success: {
                style: {
                  background: '#22bb33',
                  color: '#ffffff',
                },
              },
              error: {
                style: {
                  background: '#bb2124',
                  color: '#ffffff',
                },
              },
              loading: {
                style: {
                background: '#2285bb',
                  color: '#ffffff',
                },
              },
            }}
          />  
        </div>
        <AuthProvider>
          <BrowserRouter>
            <App appName={appName} version={version} />
            <ScrollToTop />
          </BrowserRouter>
        </AuthProvider>
      </div>
    </CacheBuster>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
