// React
import React from 'react';
// Material-UI
import { Box, Grid, Typography } from '@mui/material';
// Otras libs

// Componentes

// Services
import getSaludo from 'utils/getSaludo';
// Hooks
import useAuth from 'hooks/useAuth';


export default function SaludoUsuario() {
    // Token
    const auth = useAuth();
    // Navegación
    
    // Estados
    
    // Listas

    // Funciones

    // Vista
    return (
        <Box>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Typography variant="subtitle1" gutterBottom>
                        {getSaludo(auth.user?.nombres ?? "")}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}