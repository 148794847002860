// React
import React, { FunctionComponent } from "react";
import { ICollapseListProps } from "./ICollapseList";
import { Collapse, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const CollapseList: FunctionComponent<ICollapseListProps> = (props) => {
    const [open, setOpen] = React.useState(false);

    const _handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton onClick={_handleClick} style={{ paddingLeft: 25 }}>
                {props.icon &&
                    <ListItemIcon style={{ color: props.color ? props.color : "white" }}>
                        <props.icon fontSize="small" sx={{ color: props.color ? props.color : "white" }}/>
                    </ListItemIcon>
                }
                <ListItemText 
                    primary={<Typography variant="body1" fontWeight={600} fontSize={15} color={props.color ? props.color : "white"}>{props.title}</Typography>} 
                    style={{ marginLeft: props.icon ? 0 : 10 }}
                />
                {open 
                    ? <ExpandLessIcon style={{ color: props.color ? props.color : "white" }} /> 
                    : <ExpandMoreIcon style={{ color: props.color ? props.color : "white" }}/>
                }
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: "rgb(246 246 246 / 3%)" }}>
                {props.children}
            </Collapse>
        </>
    );
};